<template>
	<div class="vpage">
		<div class="ban-content">
			<div class="ban-box">
				<div class="ban-tt">
					北美SDE求职合约班
				</div>
				<div class="ban-desc ban-sm">
					全程1对1服务，签约包过，解决身份难题，保证拿到offer，拿不到退款
				</div>
				<div class="ban-desc">
					全程1对1服务，签约包过<br>解决身份难题，保证拿到offer<br>拿不到退款
				</div>
				<div class="ban-price ban-sm">价格方案：$25000</div>
				<div class="ban-zx">
					<div class="ban-code">
						<img src="../../assets/images/class/code.png" alt="">
					</div>
					<div class="ban-txt">详细请咨询顾问老师</div>
				</div>
				<div class="ban-price">价格方案：$25000</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {

			};
		},
		created() {


		},
		mounted() {},
		methods: {

		},
	};
</script>

<style >
	body {
		min-width: unset;
	}

	.ban-content {
		background: url(../../assets/images/class/class_bg.jpg) no-repeat center / cover;
		min-height: 100vh;
	}

	.ban-box {
		padding: 186px 30px;
		text-align: center;
	}

	.ban-tt {
		font-size: 40px;
		color: #ffffff;
	}

	.ban-desc {
		font-size: 20px;
		color: #fff;
		margin-top: 15px;
		display: none;
	}

	.ban-price {
		font-size: 34px;
		color: #ff7e00;
		margin-top: 68px;
		display: none;
	}

	.ban-zx {
		margin-top: 70px;
	}

	.ban-code {
		background-color: #fff;
		border-radius: 8px;
		width: 180px;
		height: 180px;
		padding: 12.5px;
		margin: 0 auto;
	}

	.ban-code img {
		width: 100%;
		height: 100%;
	}

	.ban-txt {
		font-size: 16px;
		margin-top: 30px;
		color: #fff;
	}
	
	.ban-sm{
		display: block;
	}
	

	@media (max-width: 768px) {
		.ban-content {
			background-image: url(../../assets/images/class/class_bgsm.jpg);
		}
		
		.ban-box{
			padding: 75px 15px;
		}
		
		.ban-tt{
			font-size: 29px;
		}
		
		.ban-desc{
			font-size: 17px;
			line-height: 36px;
			margin-top: 29px;
			display: block;
		}
		
		.ban-price{
			font-size: 24px;
			margin-top: 13px;
			display: block;
		}
		
		.ban-txt{
			font-size: 14px;
			margin-top: 20px;
		}
		
		.ban-code{
			width: 175px;
			height: 175px;
			border-radius: 4px;
			padding: 9.5px;
		}
		
		.ban-sm{
			display: none;
		}
	}
	.vpage+.house-footer{
		display: none;
	}
</style>
